import colors from "./colors";
import iconCategories from "./icons.json";

const config = {
  domains: ["ch"],
  font: '16px "Noto Sans"',
  iconCategories,
  iconGeneratorActive: true,
  id: "bernmobil", // needs to be identical to the icons directory name in public
  name: "Bernmobil",
  pictures: [
    { id: "bernmobil_bus_v1.png" },
    { id: "bernmobil_doublebus_v1.png" },
    { id: "bernmobil_triplebus_v1.png" },
    { id: "bernmobil_tram_v1.png" },
    { id: "bernmobil_tram_long_v1.png" },
  ],
  slug: "svb",
  ssoTags: ["tp.ch.svb"],
  ...colors,
};
export default config;
