import Cookies from "js-cookie";
import { Layer, RoutingControl, RoutingLayer } from "mobility-toolbox-js/ol";
import { Map } from "ol";
import { defaults as defaultInteractions } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Circle, Fill, Stroke, Style } from "ol/style";
import { shared as sharedIconImageCache } from "ol/style/IconImageCache";

import configs from "./config";
import layouts from "./config/layouts";
import { createBaseLayers, getCurrentBaseLayer } from "./utils";
import {
  AERIAL_LAYER_NAME,
  BASE_BRIGHT_NETZKARTE_MAPSET_STYLE,
  BASE_BRIGHT_NETZKARTE_SATURIERT_MAPSET_STYLE,
  BASE_LAYER_NAME,
  BASE_LAYER_PARAM,
  DID_YOU_KNOW_DIALOG_NAME,
  DRAW_TYPE_GROUP_AREA,
  DRAW_TYPE_GROUP_LINE,
  DRAW_TYPE_GROUP_POINT,
  DRAW_TYPE_GROUP_PROPERTY,
  DRAW_TYPE_ICON,
  DRAW_TYPE_LINE,
  DRAW_TYPE_POLYGON,
  DRAW_TYPE_PROPERTY,
  DRAW_TYPE_ROUTE,
  EDIT_LAYER_NAME,
  EDIT_OL_LAYER_NAME,
  exportTypes,
  HEADER_HEIGHT,
  INSPECT_LAYER_NAME,
  INSPECT_OL_LAYER_NAME,
  LS_EXPORT_FORMAT,
  LS_EXPORT_QR_CODE,
  LS_EXPORT_TYPE,
  LS_HIDE_DID_YOU_KNOW,
  LS_SNAPPING_TYPE,
  MAPBOX_STYLELAYERS,
  MAPSET_METADATA_BUS_VALUE,
  MAPSET_METADATA_FILTER,
  MAPSET_METADATA_TRAM_VALUE,
  OL_ICON_CACHE_SIZE,
  PREVIEW_PATHNAME,
  SATURATED_LAYER_NAME,
  SNAP_CONTROL_LINES,
  SNAP_CONTROL_OFF,
  SNAP_CONTROL_POINTS,
  STYLE_REVIEW_PREFIX,
} from "./utils/constants";
import endpointConfigs from "./utils/endpointConfigs";
import LayerService from "./utils/LayerService";
import { createMapboxStyleLayer, setMapboxLayer } from "./utils/mapboxStyle";

// [MAPSETW-477] Increase cache size of icons
sharedIconImageCache.setSize(OL_ICON_CACHE_SIZE);

const currLocation = `${window.location.protocol}//${window.location.host}`;
const initialWidth = 350;

const { domain, env, origin } =
  endpointConfigs[process.env.REACT_APP_ENV] || endpointConfigs.prod_ch;

const version = env === "dev" ? "dev" : "v1";
const devDot = /(dev|stag)/i.test(env) ? `dev.` : "";
const devOnlyDot = /(dev)/i.test(env) ? `dev.` : "";

const apiUrl = process.env.REACT_APP_AUTH_URL
  ? `${process.env.REACT_APP_AUTH_URL}/api/v1` // For local development
  : `${origin}/api/v1`;

const hideDidYouKnow =
  localStorage.getItem(LS_HIDE_DID_YOU_KNOW) === "true" ||
  sessionStorage.getItem(LS_HIDE_DID_YOU_KNOW) === "true";

// MAPSET-446: Saving in sessionStorage prevent the dialog to be displayed when we reload a new plan in the same tab
// or when we login
window.sessionStorage.setItem(LS_HIDE_DID_YOU_KNOW, true);

const snappingTypeOptions = [
  SNAP_CONTROL_LINES,
  SNAP_CONTROL_POINTS,
  SNAP_CONTROL_OFF,
];
const lsSnappingType = localStorage.getItem(LS_SNAPPING_TYPE);
const snappingType =
  lsSnappingType && snappingTypeOptions.includes(lsSnappingType)
    ? lsSnappingType
    : SNAP_CONTROL_POINTS;

// Befoae lsExportQrCode was a boolean true or false,
// so for backward compatibility reson we handle this usecase
let lsExportQrCode = localStorage.getItem(LS_EXPORT_QR_CODE) || 0;
if (lsExportQrCode) {
  lsExportQrCode = JSON.parse(lsExportQrCode);
  if (lsExportQrCode === true) {
    lsExportQrCode = undefined; // The default size will be used
  }
  if (lsExportQrCode === false) {
    lsExportQrCode = 0; // No qrcode
  }
}
const lsExportFormat = localStorage.getItem(LS_EXPORT_FORMAT) || "image/png";
const lsExportType = localStorage.getItem(LS_EXPORT_TYPE) || exportTypes[0];

const mapboxStyleLayers = MAPBOX_STYLELAYERS.map((styleLayer) =>
  createMapboxStyleLayer(styleLayer),
);

export const defaultBaseLayers = [
  {
    key: "ch.sbb.netzkarte",
    name: BASE_LAYER_NAME,
    style: `${STYLE_REVIEW_PREFIX}${BASE_BRIGHT_NETZKARTE_MAPSET_STYLE}`,
  },
  {
    key: "ch.sbb.netzkarte_saturiert",
    name: SATURATED_LAYER_NAME,
    style: `${STYLE_REVIEW_PREFIX}${BASE_BRIGHT_NETZKARTE_SATURIERT_MAPSET_STYLE}`,
  },
  {
    key: "world.geops.aerial_sbb",
    name: AERIAL_LAYER_NAME,
    style: `${STYLE_REVIEW_PREFIX}aerial_sbb_ch.sbb.netzkarte.aerial`,
  },
];

const ssoUrl = "https://sso.geops.io";
const { ch, io } = configs;
const configsArray = Object.values(configs);

// Define the default state object
const state = {
  app: {
    /* Django API url configuration */
    apiUrl,
    authUrl: `${process.env.REACT_APP_AUTH_URL || origin}/accounts/geops/login`,
    busFilters: [{ lines: "", provider: "" }],
    /* Busses url configuration */
    bussesStyleUrl: `https://maps.geops.io/data/busses.json`,
    config: ch,
    configs: [],
    // ssoClientId: '000326',
    currentGroupControls: {
      [DRAW_TYPE_GROUP_AREA]: DRAW_TYPE_POLYGON,
      [DRAW_TYPE_GROUP_LINE]: DRAW_TYPE_LINE,
      [DRAW_TYPE_GROUP_POINT]: DRAW_TYPE_ICON,
    },

    /* SSO api configuration */
    currentSidebarWidth: 50,
    defaultLang: "de-CH",
    dialogPosition: {
      x: document.body.clientWidth - (initialWidth + 10),
      y: HEADER_HEIGHT + 10,
    },
    dialogSize: {
      height: "auto",
      width: initialWidth,
    },
    dialogVisible: hideDidYouKnow ? false : DID_YOU_KNOW_DIALOG_NAME,

    domain,
    domainConfigs: configsArray.filter((config) =>
      config.domains.includes(domain),
    ),

    drawInfoError: null,

    drawInfos: [],
    exportBboxLockedToMap: false,

    exportFormat: lsExportFormat,
    exportLayoutConfig: {},

    exportLayouts: layouts,

    exportNorthArrow: true,

    exportQrCode: lsExportQrCode,

    exportScale: 1,

    exportSelect: false,

    exportSize: null,

    exportType: lsExportType,
    /* Feedback */
    feedbackUrl:
      process.env.REACT_APP_FEEDBACK_URL || `${apiUrl}/feedback-form`,
    fetchError: null,
    imagesUrl: `${currLocation}/static/images/`,

    inspectModeActive: false,

    isSidebarOpen: false,

    /* Jasper */
    jasperUrl: `${apiUrl}/jasperserver`,
    kmlHistory: {
      future: [],
      past: [],
      present: undefined,
    },
    /* KML */
    kmlUrl: `${apiUrl}/meta/kml`,
    languageOptions: [
      { child: "Deutsch", id: "de", value: "de-CH" },
      { child: "Français", id: "fr", value: "fr" },
      { child: "Italiano", id: "it", value: "it" },
      { child: "English", id: "en", value: "en" },
    ],
    /* Link shortener url configuration */
    linkShortenerUrl: `https://${devDot}geops.sh/api/v1/weburls`,
    /* mapsetUrl Url configuration */
    mapsetUrl: `https://mapset.${domain}`,
    oldKmlUrl: "https://maps.trafimage.ch/services/cbs/trafzeich/kml",
    /* Pictos prefix configuration */
    pictosPrefix: `${origin}/static/images/`,
    /* Icon-Generator Url configuration */
    pictosUrl: `https://icon-generator.${devOnlyDot}geops.io/pictogram`,
    prefAgencies: "sbb",
    previewPathname: PREVIEW_PATHNAME,
    /* App configuration */
    pwaActive: false,
    /* Routing url configuration */
    routingKey: `${process.env.REACT_APP_ROUTING_KEY}`,
    /* Shootme */
    screenshotUrl: "https://screenshot.geops.io",
    selectedPlan: null,
    // ssoUrl: `http://127.0.0.1:8000/openid`, //
    ssoClientId: "576922",
    ssoConfigsUrl: `${ssoUrl}/api/configs`,
    ssoOpenIdUrl: `${ssoUrl}/openid`,
    ssoRenewInterval: 480000,
    ssoUrl,
    /* StopFinder url configuration */
    stopFinderKey: `${process.env.REACT_APP_STOP_FINDER_KEY}`,
    stopFinderUrl: `https://api.geops.io/stops/${version}/`,
    /* Tiles url configuration */
    tilesKey: `${process.env.REACT_APP_VECTOR_TILES_KEY}`,
    tilesUrl: `${
      process.env.REACT_APP_VECTOR_TILES_URL || "https://maps.geops.io"
    }`,
    userInfoUrl: `${process.env.REACT_APP_AUTH_URL ? `${process.env.REACT_APP_AUTH_URL}/api/v1` : apiUrl}/userinfo`,
  },
  map: {
    center: [950401.28, 6004080.8],
    defaultBaseLayers,
    layers: [],
    rotation: 0,
    snappingType,
    styleLayers: mapboxStyleLayers.filter((styleLayer) => {
      const domains = styleLayer.get("domains");
      return domains ? domains.includes(domain) : true;
    }),
    zoom: 15,
    zoomLimits: {
      maxZoom: 20,
    },
  },
  sentry: {
    autoSessionTracking: false,
    dsn: "https://f88b48d351f24ecaba4e2ddd1b5e8e6b@sentry.geops.de/38",
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  },
};

// Parent parameter management
const parameters = new URLSearchParams(window.location.search);
const parent = parameters.get("parent");
if (parent) {
  const referrer = document.referrer || Cookies.get("referrer");
  const config = configsArray.find((conf) => {
    let allowedRegex = conf.allowedParentApplications;
    if (allowedRegex) {
      allowedRegex += "|localhost|deploy-preview";
    }
    return allowedRegex && new RegExp(`(${allowedRegex})`).test(referrer);
  });

  if (!referrer) {
    // eslint-disable-next-line no-console
    console.warn("No referrer value to test");
  }

  if (config) {
    Cookies.remove("referrer");
    Cookies.set("referrer", referrer);

    // Informations relative to the application that has opened mapset.
    state.app.parentOptions = config.getParentOptions(parent);
    state.app.config = config;

    // Keep this for test purposes, will be removed when the old backend will be removed.
    // there is a wkp.draw parameter we use the old backend)
    // if (/wkp\.draw/.test(parent)) {
    //   stateObject.app.kmlUrl = stateObject.app.oldKmlUrl;
    // }
  }
}

/* mapset.io map configuration */
if (/io/.test(domain)) {
  /* Basics */
  state.app.config = io;
  state.app.ssoClientId = "991094";
  state.app.defaultLang = "en";
  state.app.prefAgencies = "db";

  /* Map */
  state.map.center = [949653.65, 6803851.06];

  state.map.defaultBaseLayers = [
    {
      key: "world.geops.travic",
      name: BASE_LAYER_NAME,
      style: `${STYLE_REVIEW_PREFIX}travic_v2_mapset`,
    },
    {
      key: "world.geops.aerial",
      name: AERIAL_LAYER_NAME,
      style: `${STYLE_REVIEW_PREFIX}aerial`,
    },
  ];

  // Use standard German instead of Swiss German
  state.app.languageOptions.find((language) => language.id === "de").value =
    "de-DE";
}

state.app.busFilter = (feature) =>
  feature.layer?.metadata &&
  feature.layer.metadata[MAPSET_METADATA_FILTER] === MAPSET_METADATA_BUS_VALUE;

state.app.tramFilter = (feature) =>
  feature.layer?.metadata &&
  feature.layer.metadata[MAPSET_METADATA_FILTER] === MAPSET_METADATA_TRAM_VALUE;

/* Routing configuration */
const routingStyle = (feature) => {
  const mot = feature.get("mot");
  const viaPointIdx = feature.get("viaPointIdx");

  // Default style for route lines
  const stroke =
    mot &&
    new Stroke({
      color: [255, 0, 0, 1],
      lineDash: mot === "foot" ? [1, 10] : undefined,
      width: 3,
    });

  // Default style for via points
  const image =
    viaPointIdx !== undefined &&
    new Circle({
      fill: new Fill({
        color: [255, 0, 0, 1],
      }),
      radius: 6,
      stroke: new Stroke({
        color: [0, 0, 0, 1],
        width: 1,
      }),
    });

  const style = new Style({
    image,
    stroke,
  });

  return style;
};

state.map.routingControl = new RoutingControl({
  active: false,
  apiKey: `${process.env.REACT_APP_ROUTING_KEY}`,
  [DRAW_TYPE_GROUP_PROPERTY]: DRAW_TYPE_GROUP_LINE,
  [DRAW_TYPE_PROPERTY]: DRAW_TYPE_ROUTE,
  element: document.createElement("div"),
  mot: "foot",
  onRouteError: () => {},
  routingApiParams: {},
  routingLayer: new RoutingLayer({
    style: routingStyle,
  }),
  stopsApiKey: `${process.env.REACT_APP_STOP_FINDER_KEY}`,
  title: "Route zeichnen",
});

/* Layer service configuration */
const defaultLineStyle = new Style({
  stroke: new Stroke({
    color: [235, 0, 0],
    lineCap: "round",
    lineDash: [1, 10],
    width: 4,
  }),
});

// Creates new base layers.
const baseLayers = createBaseLayers(
  [...(state.app.config.baseLayers || state.map.defaultBaseLayers)],
  state.app,
);

const layers = [
  ...state.map.styleLayers,
  new Layer({
    name: EDIT_LAYER_NAME,
    olLayer: new VectorLayer({
      name: EDIT_OL_LAYER_NAME,
      // We don't put more because we are not sure how it affects the performance.
      renderBuffer: 500,
      source: new VectorSource({
        // [MAPSETW-446] To make sure we don't use the rbush tree.
        useSpatialIndex: false,
      }),
      style: defaultLineStyle.clone(),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      // [MAPSET-494] OL use this buffer value (in pixels) to find features to apply the hit detection on it.
      // 500 fit probably most of use cases (was 100 by default).
      zIndex: 100,
    }),
  }),
  new Layer({
    name: INSPECT_LAYER_NAME,
    olLayer: new VectorLayer({
      name: INSPECT_OL_LAYER_NAME,
      source: new VectorSource({
        // [MAPSETW-446] To make sure we don't use the rbush tree.
        useSpatialIndex: false,
      }),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      zIndex: 100,
    }),
    visible: false,
  }),
];

state.map.layerService = new LayerService(layers);

// We read the base layer permalink param to avoid unecessary re render when page loads.
state.map.baseLayer = getCurrentBaseLayer(
  baseLayers,
  parameters.get(BASE_LAYER_PARAM),
);

state.map.layers = [...baseLayers, ...layers];

// Apply the current base layer to all the style layers.
state.map.styleLayers.forEach((styleLayer) =>
  setMapboxLayer(styleLayer, state.map.baseLayer),
);

state.map.olMap = new Map({
  controls: [],
  interactions: defaultInteractions(),
});

export default state;
